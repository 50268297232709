/*
 * File: catalog-list.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 13th October 2022 14:32:05
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
@use "../../fascicle/fascicle-list/fascicle-list.component.scss" as
  fascicle-list;

// @import "/src/styles.scss";
@import "/src/app/core/scss/mat-table-columns.scss";

@mixin cc-catalog-list($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $primary: mat.get-color-from-palette($primary-palette, 500);

  $header-color:#B5B5B5;
  $header-font-size: 14px;
  table {
    width: 100% !important;
    //border-collapse: collapse;

  }


  .catalog-table-container {
    min-height: 600px !important;
    max-height: 82vh !important;
    border-radius: 5px !important;
    overflow: hidden;
    overflow-y: auto;
    background-color: mat.get-color-from-palette($primary-palette, A100);

    & .mat-table {
      overflow: hidden;
      & .mat-header-row.top-row {
        border: none !important;
        min-height: 20px !important;
      }
      & .mat-header-row{
        border-bottom-width: 1px;
        border-color: $header-color;
        min-height: 0px !important;
        & .mat-header-cell{
          color:$header-color;
          font-size: $header-font-size;
        }

      }
      & .mat-row {
        transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
        border-bottom-width: 1px;
        &:hover {
          background-color: inherit
        }
        & .active {
          background-color: mat.get-color-from-palette($primary-palette, 50);
          transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
        }
        & .mat-cell {
          font-size: $header-font-size !important;
          color: inherit !important;

        }


      }

      & .catalog-expanded-row {
        transition: all 3s ease;
        background-color: none;
        border-bottom: 0px !important;
      }

      & .row-fascicles {
        background-color: mat.get-color-from-palette($primary-palette, 50);
        z-index: 999 !important;
      }

      &.mat-header-cell {
        font-weight: bolder;
        font-size: 13px;
        letter-spacing: 0.25px;
        background-color: mat.get-color-from-palette($primary-palette, 100);
      }
    }
  }

  .catalog-title{
    margin:0 !important;
    transition: color 0.2s ease;
    font-weight: 500;
  }

  .catalog-title-active{
    transition: color 0.2s ease;
    // color:  mat.get-color-from-palette($primary-palette, 500);
  }

  .title-row-active{
    display: block;
  }
  .catalog-details{
  margin:0 !important;


  }
.catalog-fascicle-detail{
  padding: 0 !important;
}
  .search-field {
    width: 444px !important;
    margin-left: 20px;
    margin-top: 15px;
  }

  .new-catalog {
    height: calc(100vh - 65px) !important;
    width: 100%;
  }

  .row-expanded{
    transform: rotate(180deg) !important;
    transition: all 0.3s ease;
  }

  .row-collapsed{
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }

  .collapsed{
    height: 0px;
    min-height: 0;
    transition: all 1s ease;

   }

   .expanded{
    transition: all 1s ease;
    background-color: red;

   }


  .mat-column-checkRow {
    flex: 0 0 5%; //5
  }
  .mat-column-name {
    flex: 0 0 30%; //35
    text-align: left;
    padding: 1em 0;
  }

  .mat-column-createdBy {
    flex: 0 0 20%; //55
    text-align: left;
    padding: 1em 0;
  }

  .mat-column-modified {
    flex: 0 0 20%; //75
    text-align: left;
    padding: 1em 0;
  }

  .mat-column-pages {
    flex: 0 0 10%; //85
    text-align: left;
    padding: 1em 0;
  }

  .mat-column-expandRow {
    flex: 0 0 12%; //100
    display:flex !important;
    justify-content:flex-end!important;

  }

  .double-border{
    border-top: 2px solid #ccc;
  }


  .mat-form-field-outline{
    background-color: white;
  }

  //Include scss fascicle list
  //Important: Added to the end!!!!! (3 horas pa esto)
  @include fascicle-list.cc-fascicle-list($theme);
}
