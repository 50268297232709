/*
 * File: cc-cell-button.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Friday, 25th March 2022 15:08:59
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

 @use "sass:map";
@use "@angular/material" as mat;

@mixin cc-grid-cell-button($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $warn-palette: map-get($theme, warn);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $warn: mat.get-color-from-palette($warn-palette, 500);

  .tool-container{
    display: block ;
    position: relative;
    align-items: center;
    z-index: 999 !important;
  }


  .cc-boton{
    width: 24px;
    height: 24px;
  }

  .right-cell-tool{
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
  }

  .left-cell-tool{
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
  }

  .top-cell-tool{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bottom-cell-tool{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

  }

  .hidden {
    display: none !important;
  }

  .arrow-left{
    transform: rotate(90deg);
  }

  .arrow-down{
    transform: rotate(0deg);
  }

  .arrow-up{
    transform: rotate(180deg);
  }

  .arrow-right{
    transform: rotate(270deg);
  }

  .round-small-button{
    border-radius: 50%;
    height: 24px;
    width: 24px;
    line-height: 24px;
  }

  .round-small-button.plus{
    background-color: $primary;
    color: white;
    margin: 0 0.2em !important;

  }

  .round-small-button.minus{
    background-color: $warn;
    color: white;
  }

  .round-small-button mat-icon{
    font-size: 16px;
  }
  .grow-buttons{
    display: flex;
  }
}



// .addIcon{
//   background-color: blue;
// }

// .removeIcon{
//   background-color: red;
// }
