/*
 * File: product-details.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */


@use "sass:map";
@use "@angular/material" as mat;

@mixin cc-product-details ($theme) {
  $primary-palette: map-get($theme, primary );
  $accent-palette: map-get($theme, accent );
  $accent:mat.get-color-from-palette($accent-palette,500);

  .item-details {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: auto !important;
    visibility: visible;
    opacity: 1;
    // max-height: 100%;
    padding: 0px;
    overflow:hidden;
    flex-grow:0 !important;
    opacity: 0.2s ease-out;
    border:2px solid mat.get-color-from-palette($primary-palette, 100) ;
    background-color: #fff;
    border-radius: 10px;
    z-index: 9999;
  }

  .item-details-hide {
   opacity: 0;
   transition: opacity 0.2s ease-out;
   z-index: -1;
  }


  .product-datails{
    text-decoration: no;
    list-style: none;
    font-size: 12px;
    margin:0px;
    width: 100%;
    padding: 10px;
  }

  .product-datails li strong {
    font-weight: bolder;
    margin-right: 5px;
  }

}

