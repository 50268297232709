/* For use in src/lib/core/theming/_palette.scss */
$cc-primary: (
  50 : #ede0fd,
  100 : #d1b3fa,
  200 : #b380f7,
  300 : #944df3,
  400 : #7d26f1,
  500 : #6600ee,
  600 : #5e00ec,
  700 : #5300e9,
  800 : #4900e7,
  900 : #3700b3,
  A100 : #ffffff,
  A200 : #ded6ff,
  A400 : #b4a3ff,
  A700 : #9f8aff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$cc-secondary: (
  50 : #e2f7f0,
  100 : #b7ead9,
  200 : #88dcc0,
  300 : #58cea7,
  400 : #34c494,
  500 : #10b981,
  600 : #0eb279,
  700 : #0caa6e,
  800 : #09a264,
  900 : #059669,
  A100 : #bfffde,
  A200 : #8cffc3,
  A400 : #59ffa9,
  A700 : #40ff9c,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

