/*
 * File: product-list.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

 @use "sass:map";
 @use "@angular/material" as mat;
 @use "../product-list-item/product-list-item.component.scss" as product-list-item;
   @mixin cc-product-list($theme) {
    .product-list {
      display: list;
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;
      width: 100% !important;
      max-width: 100% !important;
      overflow: hidden;
      margin-top: 1em;

      & .product-list-item-container {
        padding: 0.4em 1em;
        border-radius: 15px !important;
        height: 100%;
        width: 90%;
        max-height: 100% !important;
        overflow: hidden;
        max-width: 100% !important;

      }

    }
    @include product-list-item.cc-product-list-item($theme);

  }
