/*
 * File: cc-cell.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Tuesday, 21st June 2022 16:01:30
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
@use "../cc-cell-item/cc-cell-item.component.scss" as item;
@use "../cc-cell-button/cc-cell-button.component.scss" as cell-button;

@mixin cc-grid-cell($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  $highlight-color: mat.get-color-from-palette($primary-palette, 50);

  @include item.cc-grid-cell-item($theme);

  .innerCell {
    border: 0px solid mat.get-color-from-palette($primary-palette, 100);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    overflow: hidden;
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1);

    &.cell-unlocked {
      // background-color: mat.get-color-from-palette($primary-palette, 100);
      border: 2px dashed mat.get-color-from-palette($primary-palette, 500) !important;
      background-color: #ddd !important;

    }

    &.cell-void {
      background-color: #ddd;
    }

    &.cell-fill {
      background-color: #fff;
      border: 1px solid #ddd;

    }

    &.cell-fill:hover {
      transition: all 0.5s ease;
      border: 1px solid mat.get-color-from-palette($primary-palette, 500);
    }

    &:hover .cell-buttons {
      display: block;
      // background-color: red !important;
      overflow: visible !important;
      height: 100%;
      width: 100%;
    }

    & .cell-buttons {
      display: none;
    }

    & .side {
      position: absolute;
      z-index: 2;
    }

    & .side.side-h {
      height: 100%;
      max-width: 80px;
      min-width: 40px;
    }

    & .side.side-v {
      width: 100%;
      max-height: 40px;
      min-height: 40px;
    }

    & .side-top {
      top: 5px;
    }

    & .side-left {
      top: 0;
      left: 5px;
    }

    & .side-right {
      top: 0;
      right: 5px;
    }

    & .side-bottom {
      bottom: 20px;
    }

     .product-grid-item {
      height: 100% !important;
      // background-color: mat.get-color-from-palette($primary-palette, 300) !important;

    }

    &.cdk-drop-list-receiving {
      // background-color: mat.get-color-from-palette($primary-palette, 100);

      transition: background-color 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.cdk-drop-list-dragging {
      background-color: $highlight-color;
      opacity: 1;
      height: 100%;
      width: calc(100% - 4px) !important;
      height: calc(100% - 4px) !important;
      // transition: background-color 250ms cubic-bezier(0, 0, 0.2, 1);
    }
    & .cdk-drag-placeholder {
      background-color: $highlight-color;

    }
  }

  .product-placeholder {
    border-radius: 5px;
    justify-content: center;
    align-items: center;

    height: 100%;
    overflow: visible;
    transition: background-color 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .hide-item {
    display: none !important;
  }

  @include cell-button.cc-grid-cell-button($theme);
}
