/*
 * File: catalog-home.component.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Friday, 17th June 2022 11:05:50
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

// .main-sidenav{
//   height: calc(100vh - 65px);
//   background-color: white;
// }

// .content-sidenav{
//   position: relative;
//   overflow: hidden;
// }
@use "sass:map";
@use "@angular/material" as mat;
@use '../catalog-list/catalog-list.component.scss'as catalog-list;
@use '../../menus/actions-menu/actions-menu.component.scss' as actions-menu;
@use '../../menus/top-menu/top-menu.component.scss' as top-menu;


@import "~@angular/material/theming";

@mixin catalog-home ($theme) {
  $primary-palette: map-get($theme, primary );
  $accent-palette: map-get($theme, accent );
  $accent:mat.get-color-from-palette($accent-palette,500);
  $primary:mat.get-color-from-palette($primary-palette,500);

  //catalog components

  @include catalog-list.cc-catalog-list($theme);

  //menus
  @include top-menu.user-menu($theme);
  @include actions-menu.cc-actions-menu-side($theme);


  // .main-sidenav{
  //  // background-color: $accent;
  // }

  .left-side-menu{
    background-color: $accent;
    height: 100%;
    min-height: 100%;
  }

  .content-sidenav{
   // background-color: $primary;
    height: 100%;
    min-height: 100%;
  }

}



