/*
 * File: product-grid.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */
@use "sass:map";
@use "@angular/material" as mat;
@use "../product-grid-item/product-grid-item.component.scss" as product-grid-item;

@mixin cc-product-grid($theme) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    width: 100% !important;
    max-width: 100% !important;
    overflow: hidden;
    align-items: center;
    align-content: center;
    margin-top: 1em;
    height: auto;
    & .product-grid-item-container {
      padding: 0.4em;
      height: 100%;
      width: 90%;
      margin: 0 auto;
      overflow: hidden;
      display: block;
    }
  }
  @include product-grid-item.cc-product-grid-item($theme);
}
