/*
 * File: fascicle-edit.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Monday, 13th June 2022 13:19:44
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;


@mixin cc-fascicle-edit($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  .select-pages{
    max-width: 80px  !important;
    width: auto;
    text-align: center;

  }

  .fascicle-desk {
    height: calc(100vh - 65px);
    margin-top: 0px;
    overflow: hidden;
  }

  .mat-sidenav {
    max-width: 40vh;
  }

  .fascice-edit-content{
    // background-color: $primary;
    overflow: hidden;
    // overflow: hidden !important;
  }

  .product-toolbar-container{
    max-height: 50% !important;
  }

  .product-list-column {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .page-paginator {
    // background-color: white;
    width: 100%;
    margin: 0 auto;
    // box-shadow: 0px 0px 20px !important;
    & .mat-select-arrow {
      margin-left: -15px !important;
      transform: rotate(180deg);
    }
  }

  .wrap-fascicle {
    opacity: 1;
  }
  .wrap-content {
    width: 100%;
    margin-top: 2em;
  }

  .page-wrap{
    display: grid;
    grid-template-columns: 50% 50% ;
    // background-color: blue;
    height: calc(100% - 60px);
    width: 95%;
    margin: 0 auto;

  }

  .page-wrap app-cc-grid {
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
  }

  ::ng-deep {
    .cdk-drop-list-dragging {
      width: 100% !important;
      max-height: 200px !important;
    }
  }


  .grid-container {
    border:0px solid black;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: space-between;
    flex-direction: row;
   }

   .grid-container-left {
    border-right: 1px solid #ddd;
   }

   .grid-container-right {
    border-left: 1px solid #ddd;
   }

   .button-page {
     background-color: white;
     transition: all ease 0.5s;
     border: 0px!important;
     cursor: pointer;
     height: 100%;
   }

   .button-page:disabled .page-icon{
     color: white;
   }
   .button-page:disabled {
    background:#ccc;
    cursor:auto;
   }

   .button-page:hover{
    background:#ccc;

    transition: all ease 0.5s;
   }

   .button-page .page-icon{
    top: 50%;
  }

  .disable-toolbar {
    background-color: $accent !important;
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    filter: opacity(20%);
    pointer-events: none;
  }
}
