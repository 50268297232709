/*
 * File: fascicle-list.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:25:10
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
// @import '/src/styles.scss';
@import "/src/app/core/scss/mat-table-columns.scss";

@mixin cc-fascicle-list($theme) {
  // Get the color config from the theme.
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $cell-font-size: 14px;


  .cdk-drag-preview{
    background-color: white !important;
  }

  .fascicle-title{
    font-weight: bold;
    font-size: 14px;
    color: $primary;

  }

  .fascicle-title:hover{font-weight: bold;}
  .table-fascicles-container {


    & .mat-table {
      overflow: visible !important;
      margin: 0em !important;
      // border-color: 1px solid mat.get-color-from-palette($primary-palette,500);
      background-color: mat.get-color-from-palette($primary-palette, A100);
      transition: background-color 500ms cubic-bezier(0, 0, 0.2, 1);

      & .mat-row {
        transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
        height: 48px !important;
        max-height: 48px !important;

        &:hover {
          background-color: inherit;
        }
        & .active {
          // background-color: mat.get-color-from-palette($primary-palette, 400);
          transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
        }
        & .mat-cell{
          font-size: $cell-font-size !important;
        }

        // &.mat-column-moveRow{
        //   padding-left:25px !important;
        // }
      }
      .row-no-hover {
        z-index: -1 !important;
      }
      .row-hover {
        z-index: 999 !important;
        // margin: 0.5em 0 !important;
        background-color: mat.get-color-from-palette(
          $primary-palette,
          A100
        ) !important;
      }
      & .mat-column-moveRow {
        flex: 0 0 5%;
      }
      & .mat-column-name {
        flex: 0 0 30% !important;
      }
      & .mat-column-author {
        flex: 0 0 20%;
      }
      & .mat-column-updatedAt {
        flex: 0 0 20%;
      }
      & .mat-column-pages {
        flex: 0 0 10%;
      }
      & .mat-column-isBlocked {
        flex: 0 0 12%;
      }
      & .mat-column-actions {
        flex: 0 0 12%;
        display: flex !important;
        justify-content: flex-end !important;
      }
      &.mat-header-cell {
        font-weight: bolder;
        font-size: 13px;
        letter-spacing: 0.25px;
        // background-color: mat.get-color-from-palette($primary-palette, 100);
      }
    }
  }

  .move-fascicle {
    cursor: pointer;
  }

  .move-fascicle-not-visible {
    display: none !important;
  }

  .actions-not-visible{
    transition: all 0.2s ease;
    opacity: 0;
  }

  .remove-background{
    background: none;
  }


}
