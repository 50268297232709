/*
 * File: catalog-sync-differences.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Tuesday, 21st June 2022 17:41:12
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
@import "/src/app/core/scss/mat-table-columns.scss";

@mixin cc-catalog-sync-differences($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $primary: mat.get-color-from-palette($primary-palette, 500);

  .waiter {
    margin: 2em auto;
  }

  .diff-table {
    width: 100%;
    margin: 2em 0;
    color: #666;
  }


  .diff-table thead tr {
    width: 100%;
    min-height: 24px !important;
    height: 24px !important;
    // background: mat.get-color-from-palette($primary-palette, 300);
    // color: mat.get-color-from-palette($primary-palette, A100);
  }

  table.diff-table tbody tr td,.diff-table tbody td {
    text-align: center !important;
    width: 25%;
    min-height: 24px !important;
    height: 24px !important;
  }

  .diff-table tbody th {
    // background: mat.get-color-from-palette($primary-palette, 400);
    width: 25%;
    min-height: 24px !important;
    height: 24px !important;
    // color: mat.get-color-from-palette($primary-palette, A100); ;
  }

  .diff-table tbody tr {
    min-height: 14px !important;
    height: 24px !important;
  }
  .diff-table tbody tr:nth-child(even) {
    width: 25%;
    min-height: 24px !important;
    height: 24px !important;
    // background-color: mat.get-color-from-palette($primary-palette, 100);
  }

  .diff-table tbody tr:nth-child(odd) {
    width: 25%;
    min-height: 24px !important;
    // background-color: mat.get-color-from-palette($primary-palette, 200);
    height: 24px !important;
  }
  .sync-info {
    width: auto;
  }
  .test-connection {
    width: 100%;
    min-width: 100% !important;
    margin-bottom: 2em;
  }

  .test-connection-info {
    margin: 2em;
    display: flex;
    min-height: 4em !important;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
}
