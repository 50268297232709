/*
 * File: top-menu.component.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;

@mixin user-menu($theme) {
  // Get the color config from the theme.
  // Get the primary color palette from the color-config.
  $primary-palette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($theme,500);
  $backdrop-color: mat.get-color-from-palette($theme,50);
  $accent: mat.get-color-from-palette($theme,500);

  .menu-img{
    height: 38px;
    margin-left: 1em;
  }
  .user-name {
    float: right;
    font-size: small;
    color: mat.get-color-from-palette($primary-palette, 200);
  }

  .fill-space {
    // This fills the remaining space, by using flexbox.
    // Every toolbar row uses a flexbox row layout.
    flex: 1 1 auto;
  }
  .top-toolbar{
    // background-color: #f0f1f2 !important;
    z-index: 2;
    position: relative;
  }
}
