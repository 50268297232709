/*
 * File: side-menu.component.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Friday, 17th June 2022 11:05:55
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
@import "~@angular/material/theming";

@mixin cc-actions-menu-side($theme) {
  // Get the color config from the theme.
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $warn-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $warn: mat.get-color-from-palette($warn-palette, 500);

  // @include mat.button-theme($theme);

  .mat-icon-button:hover .mat-button-focus-overlay {
    opacity: 0.3 !important;
  }

  .side-nav-cc-top {
    top: 0px !important;
    background-color: mat.get-color-from-palette($accent-palette, 400);
    color: mat.get-color-from-palette($accent-palette, 400-contrast);
    height: 64px !important;
  }

  .side-nav-cc {
    background-color: mat.get-color-from-palette($accent-palette, 500);
    color: mat.get-color-from-palette($accent-palette, 500-contrast);
    padding-top: 0px !important;
  }

  .item-descprition {
    margin-left: 20px;
    font-size: 14px;
  }

  .item-title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 18px !important;
  }

  .fill-space {
    // This fills the remaining space, by using flexbox.
    // Every toolbar row uses a flexbox row layout.
    flex: 1 1 auto;
  }

  .menu-img {
    cursor: pointer;
  }

  .mat-list-item.active {
    background-color: $primary;
  }

  .very_small {
    height: 24px !important;
    width: 24px !important;
    line-height: 14px !important;
    font-size: 14px;

    position: fixed;
    top: 10px;
    left: 52px;
  }

  .very_small_extended {
    left: 250px;
  }

  ::ng-deep {
    .side-nav-cc .mat-mini-fab .mat-button-wrapper {
      line-height: 20px;
      padding: 0 !important;
      // background-color: $customBackground;

      .mat-icon {
        font-size: 24px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
      }
    }
  }

  .side-menu-list-item {
    border: 0px;
  }
  .side-menu-catalog-tooltip {
    font-size: 16px !important;
  }

  mat-list-item.disabled {
    cursor: not-allowed !important;
    // opacity: 0.5;
    text-decoration: none;
  }
}
