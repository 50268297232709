/*
 * File: product-list-item.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

 @use "sass:map";
@use "@angular/material" as mat;
// @import "/src/styles.scss";

@mixin cc-product-list-item($theme) {

  $primary-palette: map-get($theme,primary);
  .product-list-item {
    margin: 0em;
    padding: 0px !important;
    border-radius: 5px !important;
    width: 100%;
    height: 60px;
    cursor: pointer;
    max-height: 60px !important;
    max-width: 100% !important;
    overflow: hidden;
    border:1px solid #b5b5b5;
    &.selected{
      border: 2px solid mat.get-color-from-palette($primary-palette,500);

    }
    & .product-list-item-header{
      width: 60px;
      height: 60px;
      min-width: 50px;
      min-height: 50px;
      padding: 0px !important;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 0.5em;
      & .img-product-list-item {
        padding: 0px !important;
        width: 100%;
        height: 100%;
        max-width: 50px;
        max-height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        object-fit: cover;
        margin: 0px !important;

      }
    }

    & .product-list-item-content{
      height: 60px;
      width: 70%;
      max-width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & .product-title {
        margin: 0 0.5em;
        font-weight: bolder;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 100%;
      }

      & .product-sku {
        margin: 0 0.5em ;
        font-weight: lighter;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 100%;
      }

    }
  }

  .img-product-drag {
    padding: 0px !important;
    width: 100%;
    height: 100%;
    max-width: 140px;
    max-height: 180px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    object-fit: cover;
    margin: 0px !important;
    opacity: 0.5;
  }


}


