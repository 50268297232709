/*
 * File: cc-grid.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */
 @use "sass:map";
@use "@angular/material" as mat;
@use "../cc-cell/cc-cell.component.scss" as cell;

@mixin cc-grid($theme) {

  @include cell.cc-grid-cell($theme);

  .page-view {
    height: 100%;
    padding: 0px 2em !important;
    margin: 0px;
    overflow: hidden;
    width: 100%;
    background-color: white;

  }

  .grid-selector {
    padding: 0px;
    display: block;
    margin-bottom: 0em;
  }

  .wrap-grid {
    width: calc(100% - 1em) !important;
    margin-top: 0.8em !important;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 0em;
  }

  .grid {
    width: 100%;
    height: 100% !important;
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    gap: 0.8em;
    display: grid;
    overflow: hidden;
  }

  .header-grid{
    // background-color: red;
    width: 100%;
  }

  .footer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
  }

  .footer-page{
    width: 100%;
    padding: 1em 2em;

  }
  .footer-page-left {
    text-align: left;
    margin-left: 1em;
  }

  .footer-page-right {
    text-align: right;
    margin-right: 1em;
  }

  .footer-remove {
    text-align: right;
  }


  .logging {
    background-color: rgba($color: #333, $alpha: 0.3);
    position: fixed;
    top: 66px;
    right: 5px;
    width: 300px;
    padding-left: 1em;
    padding-top: 1em;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
    overflow-y: scroll;
    height: 100%;
  }

}

