/*
 * File: product-toolbar-search.component.scss
 * Project: catalog-cloud
 * File Created: Sunday, 6th March 2022 22:46:11
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Monday, 13th June 2022 14:10:49
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */
@use "sass:map";
@use "@angular/material" as mat;
@use "../product-toolbar-search-info/product-toolbar-search-info.component.scss" as product-toolbar-search-info;

@mixin cc-product-toolbar-search($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  .product-toolbar-search{
    background-color: mat.get-color-from-palette($primary-palette,A100);
    box-shadow:0px 0px 10px !important;
    margin-bottom: 10px;
    & .search-form {
      padding: 0.5em 1em;

      & .search-form-input {
        border-radius: 10px;
        padding: 0.3em;
        border: 1px solid mat.get-color-from-palette($primary-palette,500);
        height: 24px;
      }
    }

  }

  .product-toolbar-title{
    // color: #b5b5b5;
    // font-weight: bold;
    margin-bottom: 1em;
  }

  .product-toobar-title-grey{
    color: #b5b5b5;
    margin-bottom: 1em;
  }

  .chips-container {
    // margin-left: 1em;
    // background-color: mat.get-color-from-palette($accent-palette,400);
    margin-bottom: 1em;
    width: 100%;
    & .mat-chip-list-wrapper{
      margin-left: 1em;
    }
  }

  .btn-show-filters{
    transform: rotate(0deg);
    transition: all 0.4s ease;

  }
  .btn-show-filters-expanded{
    transform: rotate(180deg) !important;
    transition: all 0.4s ease;
  }

  .filters-form{
    padding: 0.2em 1em;
  }

  .search-type-radio-group-label{
    display: flex;
    flex-direction: column;
    margin: 0 0;
    align-items: flex-start;
    }

  .search-type-radio-button{
    margin: 0px 2em;
  }

  .mat-radio-inner-circle{
    background-color: mat.get-color-from-palette($primary-palette,500);
  }

  @include product-toolbar-search-info.cc-product-toolbar-search-info($theme);


}
