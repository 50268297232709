/*
 * File: cc-cell-item.component.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Tuesday, 29th March 2022 12:03:50
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
@import "/src/app/core/scss/mat-table-columns.scss";

@mixin cc-grid-cell-item($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  .cell-card {
    display: flex;
    flex-direction: column;
    min-height: 100% !important;
    max-height: 100% !important;
    padding: 0px;
    overflow: hidden;
    flex-grow: 0 !important;
    border-radius: 5px;
    &.selected {
      transition: all 0.2s ease;
      border:2px solid  mat.get-color-from-palette($primary-palette, 500) !important;
    }
    & .cell-card-header {
      overflow: hidden;
      & .img-product {
        object-fit:scale-down;
        width: 60%;
        height: 60%;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    & .cell-card-content {
      overflow: hidden;
      // padding: 0 1em;
      display: flex;
      bottom: 0px !important;
      flex-direction: column;
      height: 20%;
      min-height: 20%;
      max-height: 20%;
      width: 100%;
      margin-bottom: 1em;
      // position: absolute;
      & .product-sku {
        font-weight: lighter;
        font-size: 11px;
        text-overflow: ellipsis;
        padding: 0em 1em;
      }

      & .product-title {
        width: calc(100% - 2em);
        font-weight: bolder;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        padding: 0em 1em;
      }
    }

    & .cell-toolbar {
      position: relative;
      left: 0px;
      bottom: 0px;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      opacity: 0;
      transition: 0.3s;
      padding: 0.2em 0.5em;
      // background-color: mat.get-color-from-palette($primary-palette,200);
      height: 20%;
      margin-bottom: 0%;
      overflow: hidden;
      display: block;
      z-index: 999 !important;

      &:hover {
        opacity: 1;
        transition: 0.3s;
        // background-color: mat.get-color-from-palette($primary-palette,200);
        // box-shadow: 0px 5px 15px !important;

      }
      & button {
        opacity: 1;
        transition: 0.3s;
      }
    }
    &.backModel-Base {
      background-color: mat.get-color-from-palette($primary-palette, 50);
    }

    &.backModel-3x2 {
      background-color: mat.get-color-from-palette($primary-palette, 300);
    }

    &.backModel-2x1 {
      background-color: mat.get-color-from-palette($primary-palette, 500);
    }

    &.backModel-Destacado {
      background-color: mat.get-color-from-palette($primary-palette, 700);
    }
  }

.item-drag-product{
  display: none;
}

.cell-current-model{
  display: flex;
  align-items: center;
  color: mat.get-color-from-palette($primary-palette, 500);
  width: 100%;
  background-color:  rgba($color: mat.get-color-from-palette($primary-palette, 100), $alpha: 0.2);
  z-index: 99 !important;
  margin-bottom: 0.5em;
  transition: all 0.5s ease;

}

.cell-current-model:hover{
  background-color: rgba($color: mat.get-color-from-palette($primary-palette, 100), $alpha: 1.0);
  transition: all 0.5s ease;

}

.cell-current-model-data{
  opacity: 1 !important;
  margin-left: 1em;
  display: flex;
  align-items: center;
  margin-left: 1em;
  padding: 0.2em;
}

.cell-current-model-data span {
  margin-left: 0.3em
};

.item-drag-product img{
  width: 100%;
  height: 100%;
}

  $baseColor: 0;


}
