/*
 * File: styles.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:38:21
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Plus imports for other components in your app.

@use "/src/app/catalog-cloud/components/user/login/login.component.scss" as
  cc-user-login;
@use "/src/app/catalog-cloud/components/catalog/catalog-home/catalog-home.component.scss"
  as cc-home;
@use "/src/app/core/components/modal-dialog/modal-dialog.component.scss" as
  cc-modal-dialog;
@use "/src/app/catalog-cloud/components/fascicle/fascicle-edit/fascicle-edit.component.scss"
  as fascicles;
@use "/src/app/catalog-cloud/components/grid/cc-grid/cc-grid.component.scss" as
  cc-grid;
@use "/src/app/catalog-cloud/components/product/product-details/product-details.component.scss"
  as product-details;
@use "/src/app/catalog-cloud/components/product/product-toolbar/product-toolbar.component.scss"
  as product-toolbar;
@use "/src/app/catalog-cloud/components/catalog/catalog-sync-differences/catalog-sync-differences.component.scss"
  as catalog-sync-differences;


@import '/src/app/core/scss/catalog-cloud-theme.scss';

  // Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// $cc-primary: mat.define-palette(mat.$indigo-palette);
// $cc-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

//  @import '/src/app/core/scss/catalog-cloud-  palette.scss';
@import "/src/app/core/scss/catalog-cloud-palette.scss";

$cc-primary-base: mat.define-palette($cc-primary);
$cc-primary-green: mat.define-palette($cc-primary);

$cc-accent-base: mat.define-palette($cc-secondary);

// The warn palette is optional (defaults to red).
$cc-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $cc-base-theme: mat.define-light-theme((
//   color: (
//     primary: $cc-primary-base,
//     accent: $cc-accent-base,
//     warn: $cc-warn,
//     branch: $cc-primary-green
//   )
// ));
$cc-base-theme: mat.define-light-theme(
  (
    color: (
      primary: $cc-primary-base,
      accent: $cc-accent-base,
      warn: $cc-warn,
      branch: $cc-primary-green,
    ),
  )
);

$cc-base-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $cc-primary-green,
      accent: $cc-accent-base,
      warn: $cc-warn,
      branch: $cc-primary-base,
    ),
  )
);

$cc-green-theme: mat.define-light-theme(
  (
    color: (
      primary: $cc-primary-green,
      accent: $cc-accent-base,
      warn: $cc-warn,
      branch: $cc-primary-base,
    ),
  )
);

// $primary: mat.get-color-from-palette($cc-primary-base,500);
// $accent: mat.get-color-from-palette($cc-accent,500);
// $warn: mat.get-color-from-palette($cc-warn,500);

// $active-row-parent:  #04A1FF;
// $active-row-child: #E2F2FF;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($cc-theme);

@include mat.autocomplete-theme($cc-base-theme);
@include mat.button-theme($cc-base-theme);
@include mat.card-theme($cc-base-theme);
@include mat.checkbox-theme($cc-green-theme);
@include mat.chips-theme($cc-base-theme);
@include mat.core-theme($cc-base-theme);
@include mat.dialog-theme($cc-base-theme);
@include mat.form-field-theme($cc-base-theme);
@include mat.icon-theme($cc-base-theme);
@include mat.list-theme($cc-base-theme);
@include mat.menu-theme($cc-base-theme);
@include mat.paginator-theme($cc-base-theme);
@include mat.progress-bar-theme($cc-base-theme);
@include mat.progress-spinner-theme($cc-base-theme);
@include mat.table-theme($cc-base-theme);
@include mat.toolbar-theme($cc-base-theme);
@include mat.tooltip-theme($cc-base-theme);
@include mat.snack-bar-theme($cc-base-theme);
@include mat.select-theme($cc-base-theme);
@include mat.slide-toggle-theme($cc-base-theme);
@include mat.stepper-theme($cc-base-theme);

//Main theme
@include cc-theme($cc-base-theme);
//User theme
@include cc-user-login.cc-user-login($cc-base-theme);

//Home theme
@include cc-home.catalog-home($cc-base-theme);

//Catalog differences
@include catalog-sync-differences.cc-catalog-sync-differences($cc-base-theme);

//Fascicle theme
@include fascicles.cc-fascicle-edit($cc-base-theme);

//Product base
@include product-toolbar.cc-product-toolbar($cc-base-theme);
@include product-details.cc-product-details($cc-base-theme);

// //Grid theme
@include cc-grid.cc-grid($cc-base-theme);

// @include cc-mat-form-field.cc-mat-form-field($cc-base-theme);
@include cc-modal-dialog.cc-modal-dialog($cc-base-theme);

@mixin popups($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $warn-palette: map-get($theme, warn);
  .pop- {
    background-color: mat.get-color-from-palette($primary-palette, 500);
    &info {
      background-color: mat.get-color-from-palette($primary-palette, 500);
    }
    &error {
      background-color: mat.get-color-from-palette($accent-palette, 500);
    }
    &warn {
      background-color: mat.get-color-from-palette($warn-palette, 500);
    }
  }
}

@include popups($cc-base-theme);

.main-title{
  font-weight: 500;
}


/***
Drag And Drop CSS
*/

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
