/*
 * File: product-toolbar.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Friday, 3rd June 2022 10:00:11
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;
@use "../product-grid/product-grid.component.scss" as product-grid;
@use "../product-list/product-list.component.scss" as product-list;
@use "../product-toolbar-search/product-toolbar-search.component.scss" as
  product-toolbar-search;
@use "../product-toolbar-product-details/product-toolbar-product-details.component.scss"
  as product-toolbar-details;
// @import "/src/styles.scss";
// @import "/src/app/core/scss/mat-table-columns.scss";

@mixin cc-product-toolbar($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  .product-toolbar {
    height: calc(100vh - 65px);
    background-color: mat.get-color-from-palette($primary-palette, A100);
    overflow: hidden;
    // display: grid;
    & .data-list {
      background-color: mat.get-color-from-palette($primary-palette, A100);
      overflow: none;
      //height: 100% !important;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #999;
    border-radius: 5px !important;
    margin: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: initial;
  }

  .button-scroll-top {
    margin-bottom: 1em;
    margin-right: 1em;
    position: absolute;
  }

  .product-toolbar-top {
    overflow: hidden !important;
    min-height: 100%;
  }

  .product-toolbar-grid {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .product-toolbar-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .product-toolbar-details {
    z-index: 2;
    min-height: 40px;
    height: 40px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #666;
    transition: height 0.5s ease;

  }
  .product-toolbar-details-expanded{
    min-height: 359px !important;
    transition: height 0.5s ease;

  }

  @include product-grid.cc-product-grid($theme);
  @include product-list.cc-product-list($theme);
  @include product-toolbar-search.cc-product-toolbar-search($theme);
  @include product-toolbar-details.cc-product-toolbar-product-details($theme);
}
