/*
 * File: product-toolbar-product-details.component.scss
 * Project: catalog-cloud
 * File Created: Monday, 7th March 2022 12:47:46
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Friday, 3rd June 2022 10:11:17
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */
@use "sass:map";
@use "@angular/material" as mat;
@mixin cc-product-toolbar-product-details($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $header-color: #5e5e5e;




  .header-product-info-title {
    color: $header-color;
  }
  .product-info-card {
    background-color: mat.get-color-from-palette($primary-palette, A100);
    padding: 1em;
    border-radius: 0px !important;
    border-left: 1px solid #ccc;
    bottom: 0px;
    position: relative;
    height: 325px;
    max-height: 325px;
    overflow-y: auto;
    // margin-bottom: 405px;
    overflow-wrap: break-word;
    & .product-datails {
      text-decoration: no;
      list-style: none;
      font-size: 12px;
      margin: 0px;
      margin-top: 1em;
      width: 100%;
      padding: 0px;
      text-overflow: ellipsis;
      overflow-y: auto;
    }

    & .product-datails li {
      margin-bottom: 0.2em;
    }
  }

  .product-info-card-header {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
  }

  .product-info-card-titles {
    font-size: 16px;
    padding-left: 2em;
  }

  .product-img-container {
    height: 100px;
    width: 70px;
    & .product-img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .reduce-header-margin {
    margin-bottom: 0px !important;
  }
  .product-info-title {
    background-color: rgba(
      $color: mat.get-color-from-palette($primary-palette, A100),
      $alpha: 0.2
    );
    width: 100%;
    border-left: 1px solid #ccc;
    padding: 0.4em 1em;
    box-shadow: 0px 0px 20px !important;
    margin-bottom: 2em;
    height: 40px !important;
  }

  .btn-show-info {
    transform: rotate(0deg);
    transition: all 0.5s ease;
  }

  .btn-hide-info {
    transform: rotate(180deg) !important;
    transition: all 0.5s ease;
  }
}
