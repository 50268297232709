/*
 * File: modal-dialog.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:23:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;

@mixin cc-modal-dialog($theme)
{
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $warn-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $warn: mat.get-color-from-palette($warn-palette, 500);

  .dialog-headline{
    font-weight: 500;
    margin-top: -1em;
    margin-left: -1em;
    margin-right: -1em;
    margin-bottom: 0;
    padding: 1em;
    text-align: center;
    font-size: 1.5em;
    // background-color: $primary;
    color:black;
  }

  .dialog-close{
    top: -60px;
    position: relative;
  }

  .dialog-content{
    padding-top: 30px;
  }

  .dialog-actions{
    padding-left: 30px !important;
    padding-right: 20px !important;
  }

  .mat-dialog-content{
    max-height: 100%;
  }

  ::ng-deep .mat-dialog-container {
    padding: 20px !important;
    overflow: hidden;
  }



}
