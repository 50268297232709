/*
 * File: product-toolbar-search-info.component.scss
 * Project: catalog-cloud
 * File Created: Sunday, 6th March 2022 23:00:15
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Monday, 13th June 2022 12:30:38
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */


@use "sass:map";
@use "@angular/material" as mat;

@mixin cc-product-toolbar-search-info($theme) {
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  .mat-button-toggle {
    background-color: mat.get-color-from-palette($primary-palette,A100);
    color:mat.get-color-from-palette($primary-palette,100);

  }
  .border-toolbar-no-sides{
    border: 0px;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
  }
.icon-product-toolbar{
  color: #666;
}
  .border-toolbar{
    border:1px solid #ccc;
    height: 32px;
    align-items: center;
  }

  .border-toolbar .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
    padding: 0px;
  }
  .border-toolbar button{
    width: 32px;
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary-palette,50);
    color:  mat.get-color-from-palette($primary-palette,500);
  }

  .buttons-toogle-container{
    padding: 2em 1em;

  }
}

