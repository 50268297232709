/*
 * File: catalog-cloud-theme.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:25:10
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use 'sass:map';
@use "@angular/material" as mat;


@mixin cc-theme($theme)
{
  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $warn-palette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $warn: mat.get-color-from-palette($warn-palette, 500);



/* Catalog Cloud Specific Styles */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 400;
  color: #666;
}

a {
  text-decoration: none;
}
a:hover {
  font-weight: 550;
}

.logo-svg {
  width: 240px;
  height: 240px;
  transform: scale(2);
}



}

