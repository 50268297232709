/*
 * File: product-grid-item.component.scss
 * Project: catalog-cloud
 * File Created: Thursday, 17th February 2022 11:48:25
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Tuesday, 29th March 2022 11:33:56
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use "sass:map";
@use "@angular/material" as mat;

@mixin cc-product-grid-item($theme) {
  $primary-palette: map-get($theme, primary);
  $border-product-color: #B5B5B5;


  .product-grid-item {
    display: flex;
    flex-direction: column;
    height: auto !important;
    padding: 0px;
    overflow: hidden;
    flex-grow: 0 !important;
    background-color: mat.get-color-from-palette($primary-palette, A100);
    border-radius: 5px;
    border:1px solid $border-product-color;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      // background-color: mat.get-color-from-palette($primary-palette, 200);
      //color:  mat.get-color-from-palette($primary-palette, A100);
      transition: all 0.2s ease;

    }
    &.selected {
      transition: all 0.2s ease;
      border:2px solid  mat.get-color-from-palette($primary-palette, 500) !important;
    }
    & .product-grid-item-header {
      display: flex;
      flex-direction: row;
      flex-grow: 0.3;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      & .img-product {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;

        width: 100px;
        height: 87px;
      }
    }
    & .product-grid-item-content {
      flex-grow: 0.3;
      overflow: hidden;
      padding: 1em;
      display: flex;
      flex-direction: column;
      & .product-sku {
        font-weight: lighter;
        font-size: 14px;
        text-overflow: ellipsis;
      }

      & .product-title {
        width: 100%;
        font-weight: bolder;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .drag-preview-img-product {
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 10px;
    background-color: mat.get-color-from-palette($primary-palette, A100);
    border: 2px solid mat.get-color-from-palette($primary-palette, 500);
    overflow: hidden !important;
    text-align: center !important;
  }

  .drag-preview-img-product img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 80%;
    max-height: 80%;

  }
}
