/*
 * File: login.component.scss
 * Project: catalog-cloud
 * File Created: Wednesday, 22nd December 2021 12:48:17
 * Author: Tomás Muniesa (tomas@cege.es)
 * -----
 * Last Modified: Thursday, 17th March 2022 11:25:10
 * Modified By: Tomás Muniesa (tomas@cege.es>)
 * -----
 * Copyright 2021 - 2022 Nousmedis, CeGe
 */

@use 'sass:map';
@use "@angular/material" as mat;

@mixin cc-user-login($theme) {

  $primary: map-get($theme, primary);

.login-background{
  height: 100vh;
  width: 100%;

  background: rgb(0,118,231);
  background: -moz-linear-gradient(45deg, rgba(0,118,231,1) 0%, rgba(49,177,147,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(0,118,231,1) 0%, rgba(49,177,147,1) 100%);
  background: linear-gradient(45deg, rgba(0,118,231,1) 0%, rgba(49,177,147,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0076e7",endColorstr="#31b193",GradientType=1);
}


.login-container  {
  width: 540px;
  height: 516px;
  padding: 0px;
  color: mat-get;
}

.login-container mat-card-title{
  padding-top: 53px;
  padding-left: 65px;
  padding-right: 65px;
}

.login-container mat-card-content{
  padding: 4em;
}

.login-title{
  margin: 0px !important;
}

.full-row {
  width: 100%;

}

.mat-input-element{
  font-size: 16px !important;
}

.remember-login{
  color: mat.get-color-from-palette($primary,500);
}

.register-link{
  color: mat.get-color-from-palette($primary,500);
}

.link-forget{
  color: mat.get-color-from-palette($primary,500);
}

.link-forget:hover{
  color: mat.get-color-from-palette($primary,700);
}



/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  box-shadow: 0 0 0 30px white inset !important;
 -webkit-box-shadow: 0 0 0 30px white inset !important;
 font-size: 16px !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill
{
  font-size: 16px !important;
}

}
